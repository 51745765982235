import React, { FC } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { responseType } from '@helpers/guarantee.helper';

import RegisterResponseTemplate from '../register-response-template/RegisterResponseTemplate.component';
import { PageType } from '@constants/pageType.constants';

interface IProblemDuringRegister {
  isSNDuplicated: boolean;
}

const ProblemDuringRegister: FC<IProblemDuringRegister> = ({isSNDuplicated}) => {
  const intl = useIntl();

  return (
    <RegisterResponseTemplate
      header={intl.formatMessage({ id: 'guarantee__responseHeaderFail' })}
      descriptionPart1={intl.formatMessage({ id: 'guarantee__responseDescriptionFail' })}
      buttonText={intl.formatMessage({ id: 'guarantee__responseButtonGuarantee' })}
      result={responseType.Fail}
      isSNDuplicated={isSNDuplicated}
      isSNDuplicatedText={intl.formatMessage({ id: 'guarantee__responseDuplicate' })}
      buttonTargetPage={PageType.WARRANTY}
    />
  );
};

export default ProblemDuringRegister;
