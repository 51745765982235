import React, { FC } from 'react';

import { Segment } from '@components/segment/Segment';
import ProblemDuringRegister from '@components/form-response/problem-during-register/ProblemDuringRegister.component';
import { GatsbySeo } from 'gatsby-plugin-next-seo/lib';
import { useIntl } from 'gatsby-plugin-intl';

interface IRegistrationProblem {
  location: {
    state?: {
      isSNDuplicated: boolean | undefined;
    }
  }
}

const RegistrationProblemTemplate: FC<IRegistrationProblem> = (props) => {
  const intl = useIntl();

  return (
      <>
        <GatsbySeo
          noindex={true}
          nofollow={true}
          title={`Avionaut - ${intl.formatMessage({ id: 'metaTitle__registrationProblem' })}`}
        />
        <Segment>
          <ProblemDuringRegister
            isSNDuplicated={props.location.state ? props.location.state.isSNDuplicated : false}
          />
        </Segment>
      </>
    )
};

export default RegistrationProblemTemplate;
